import React from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import AuthRoute from './AuthRoute';
import Layout from '../Layout';
import config from './config';

export default () => {
  return (
    <HashRouter>
      <Layout>
        <Switch>
          {config.map(item => {
            const { component: Component, ...args } = item;
            return <Route key={item.path} {...args} render={props => AuthRoute({ ...props, ...item })} />;
          })}
        </Switch>
      </Layout>
    </HashRouter>
  );
};
