import { createStore } from 'redux';
import { Provider, connect as reduxConnect } from 'react-redux';
import { composeWithDevTools as devTools } from 'redux-devtools-extension';
import FCG from 'fcg-api';

// type
const SET_DATA = 'SET_DATA';
const SET_LANGUAGE = 'SET_LANGUAGE';
const LANGUAGE = 'LANGUAGE'; // 缓存语言枚举

const defaultState = {
  LANGUAGE: {
    select: localStorage[LANGUAGE] || 'zh_CN',
    package: require('../#language/#index.js')
  }
};

// reducer
const rootReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_DATA:
      return { ...state, ...action.payload };
    case SET_LANGUAGE:
      return {
        ...state,
        LANGUAGE: {
          ...state.LANGUAGE,
          select: action.payload
        }
      };
    default:
      return state;
  }
};

const isEnvDevelopment = process.env.NODE_ENV !== 'production';

const store = createStore(rootReducer, isEnvDevelopment && devTools());

const connect = (...args) => {
  return reduxConnect(state => {
    const object = {};
    args.forEach(item => {
      if (item === 'LANGUAGE') {
        object['LANGUAGE'] = state[item].package[state[item].select];
        object['language'] = state[item].select;
      } else {
        object[item] = state[item];
      }
    });
    return { S: object };
  });
};

// api类
const FcgApi = new FCG.API({
  // Api密钥
  token: 'fcg-2020',
  // 开启调试
  debug: true,
  // 开启缓存
  cache: false,
  // 接口重连次数
  retry: 15,
  // 接口重连的间隔
  retrydelay: 300,
  // 接口超时时间
  timeout: 60000,
  // 订阅数据
  subscribe: data => store.dispatch({ type: SET_DATA, payload: data })
});

// 设置语言
const setLanguage = select => {
  const pk = Object.keys(store.getState()[LANGUAGE].package);
  if (pk.includes(select)) {
    store.dispatch({ type: SET_LANGUAGE, payload: select });
    setTimeout(() => (localStorage[LANGUAGE] = select));
  } else {
    /* eslint-disable */
    console.error('设置语言无效，不是合法值', pk);
  }
};

export { Provider, connect, store, FcgApi, FCG, setLanguage };
