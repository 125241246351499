import React from 'react';
import { Layout } from 'antd';

import styles from './style.module.less';

const Main = ({ children }) => {
  return <Layout.Content className={styles.main}>{children}</Layout.Content>;
};

export default Main;
