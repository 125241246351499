import React from 'react';

const routes = [
  {
    path: '/',
    auth: false,
    exact: true,
    component: React.lazy(() => import('@app/pages/Home'))
  },
  {
    path: '/trade',
    auth: false,
    exact: true,
    component: React.lazy(() => import('@app/pages/Trade'))
  },
  {
    path: '/partner',
    auth: false,
    exact: true,
    component: React.lazy(() => import('@app/pages/Partner'))
  },
  {
    path: '/activity',
    auth: false,
    exact: true,
    component: React.lazy(() => import('@app/pages/Activity'))
  },
  {
    path: '/mining',
    auth: false,
    exact: true,
    component: React.lazy(() => import('@app/pages/Mining'))
  }
];

export default routes;
