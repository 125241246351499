module.exports = {
  layout: {
    header: {
      1.1: '首页',
      1.2: '合约交易',
      1.3: '全球合伙人',
      1.4: '赠金活动',
      1.5: '天天挖矿',
      1.6: '更多菜单',
      1.7: '新手指南',
      1.8: '合约问题',
      1.9: '常见问题',
      2.1: '登录',
      2.2: '注册',
      2.3: '简体中文',
      2.4: 'English'
    }
  }
};
