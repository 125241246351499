/* eslint-disable */
module.exports = {
  layout: {
    header: {
      1.1: 'Home',
      1.2: 'Contract Trade',
      1.3: 'Partnership',
      1.4: 'Earn Crypto',
      1.5: 'Daily Mining',
      1.6: 'More',
      1.7: "Beginner's Guide",
      1.8: 'Contracts Guide',
      1.9: 'FAQ',
      2.1: 'Login',
      2.2: 'Get Started',
      2.3: '简体中文',
      2.4: 'English'
    }
  }
};
