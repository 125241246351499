import React from 'react';
import ReactDOM from 'react-dom';
import * as sw from './#config/sw';
import Router from './#components/Router';
import { Provider, store } from './#config/store';
import './index.less';

ReactDOM.render(
  <Provider store={store}>
    <Router />
  </Provider>,
  document.getElementById('root')
);

sw.unregister();
