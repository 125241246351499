import React from 'react';
import { Layout } from 'antd';
import { useWindowScroll } from 'react-use';
import { NavLink } from 'react-router-dom';
import { DownOutlined } from '@ant-design/icons';
import { Menu, Dropdown } from 'antd';
import { useLocation } from 'react-router-dom';
import { connect, setLanguage } from '@app/#config/store';
import styles from './style.module.less';
import './style.less';

/* eslint-disable */
const NavMenu = L => (
  <Menu>
    <Menu.Item>
      <a>{L?.['1.7']}</a>
    </Menu.Item>
    <Menu.Item>
      <a>{L?.['1.8']}</a>
    </Menu.Item>
    <Menu.Item>
      <a>{L?.['1.9']}</a>
    </Menu.Item>
  </Menu>
);

const LanguageMenu = () => (
  <Menu>
    <Menu.Item>
      <a onClick={() => setLanguage('zh_CN')}>简体中文</a>
    </Menu.Item>
    <Menu.Item>
      <a onClick={() => setLanguage('en_US')}>English</a>
    </Menu.Item>
  </Menu>
);

const Header = props => {
  const { y } = useWindowScroll();
  const location = useLocation();
  const R = ['/trade'];
  const isOpacityBg = R.includes(location.pathname);
  const L = props?.S?.LANGUAGE?.layout?.header;
  return (
    <Layout.Header className={styles.header} style={{ backgroundColor: isOpacityBg ? 'rgb(26, 37, 43)' : `rgba(26, 37, 43, ${y / 30})` }}>
      <div className={styles.logo}>
        <img src={require('@app/assets/svg/logo.svg')} alt="logo" />
      </div>
      <div className={styles.nav}>
        <NavLink exact to="/">
          {L?.['1.1']}
        </NavLink>
        <NavLink exact to="/trade">
          {L?.['1.2']}
        </NavLink>
        <NavLink exact to="/partner">
          {L?.['1.3']}
        </NavLink>
        <NavLink exact to="/activity">
          {L?.['1.4']}
        </NavLink>
        <NavLink exact to="/mining">
          {L?.['1.5']}
        </NavLink>
        <Dropdown overlay={NavMenu(L)}>
          <NavLink exact to="/f" onClick={e => e.preventDefault()}>
            {L?.['1.6']} <DownOutlined />
          </NavLink>
        </Dropdown>
      </div>
      <div className={styles.option}>
        <NavLink to="/login"> {L?.['2.1']}</NavLink>
        <NavLink to="/regter"> {L?.['2.2']}</NavLink>
        <Dropdown overlay={LanguageMenu()}>
          <NavLink exact to="/f" onClick={e => e.preventDefault()}>
            {props?.S?.language === 'zh_CN' && L?.['2.3']}
            {props?.S?.language === 'en_US' && L?.['2.4']}
            &nbsp;
            <DownOutlined />
          </NavLink>
        </Dropdown>
      </div>
    </Layout.Header>
  );
};

export default connect('LANGUAGE')(Header);
